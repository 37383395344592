import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { motion } from 'framer-motion';

// hooks & querys
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '../components/pageMeta';
import BlockZone from '../components/blockZone/zone.development';

import { ProjectsFeed } from '../components/blockZone/blocks';

const ProjectTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;
  const { relatedProjects } = data || {};
  const { title, subheading, meta, pageBlocks, themeDark } = page || {};

  useEffect(() => {
    if (themeDark) {
      document.documentElement.classList.add('themeDark');
    } else {
      document.documentElement.classList.remove('themeDark');
    }
  }, [themeDark]);

  return (
    <>
      <PageMeta {...meta} />
      <div className="px-gutter py-32 md:pt-64">
        <motion.h1
          className="text-3xl mb-4 md:text-6xl md:mb-6"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut', delay: 0.5 }}
        >
          {title}
        </motion.h1>
        <motion.h4
          className="text-xl md:text-2xl"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut', delay: 0.8 }}
        >
          {subheading}
        </motion.h4>
      </div>
      {pageBlocks && <BlockZone {...pageBlocks} />}
      <div className="py-28">
        <ProjectsFeed
          title="Other Projects"
          scopedProjects={relatedProjects && relatedProjects.edges}
        />
      </div>
    </>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    page: sanityProject(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
      title
      subheading
      themeDark
    }
    relatedProjects: allSanityProject(limit: 4, filter: { slug: { current: { ne: $slug } } }) {
      edges {
        node {
          title
          subheading
          slug {
            current
          }
        }
      }
    }
  }
`;
